//react component called StartClockingModal

import React, {useState} from "react";
import {Button, Group, LoadingOverlay, Modal, Space, Stepper, Text, TextInput} from "@mantine/core";
import {useUpdateClockingMutation} from "../../redux/apis/clockingApi";
import {useForm} from "@mantine/form";
import {showNotification} from "@mantine/notifications";
import {IClocking} from "../../models/clocking";
import SignaturePad from "../SignaturePad";
import FileUploader from "../../components/FileUploader";

interface Props {
  clocking: IClocking
  onClose: () => void
}

export const EndClockingModal: React.FC<Props> = ({clocking, onClose}: Props) => {
  const [loading, setLoading] = useState(false);
  
  const [active, setActive] = useState(0);
  const nextStep = () => {
    if (active === 0 && (!form.values.referente || form.values.referente === "")) {
      setActive(2);
    } else {
      setActive((current) => (current < 3 ? current + 1 : current));
    }
  };
  const prevStep = () => {
    if (active === 2 && (!form.values.referente || form.values.referente === "")) {
      setActive(0);
    } else {
      setActive((current) => (current > 0 ? current - 1 : current));
    }
  };
  
  const [updateClocking, {isLoading}] = useUpdateClockingMutation();
  const [submitting, isSubmitting] = React.useState(false);
  const form = useForm({
    initialValues: {
      id: clocking.id,
      dateStart: clocking.dateStart,
      dateEnd: new Date(),
      userId: clocking.userId,
      userName: clocking.userName,
      customerId: clocking.customer.id,
      signature: "",
      service: clocking.service,
      serviceDescription: clocking.serviceDescription,
      referente: clocking.referente,
      photos: []
    },
  });
  
  const handleCreateClocking = async (values) => {
    isSubmitting(true);
    values.dateEnd = new Date();
    if (values.photos.length === 0 && !values.signature) {
      showNotification({
        title: "Error",
        message: "Inserire una foto o un referente, con la relativa firma",
        color: "red",
      });
      isSubmitting(false);
      return;
    }
    await updateClocking(values).unwrap()
      .then(async () => {
        showNotification({
          title: "Success",
          message: "Timbratura creata con successo",
          color: "teal",
        });
      })
      .catch((err) => {
        showNotification({
          title: "Error",
          message: err.error,
          color: "red",
        });
      });
    isSubmitting(false);
    onClose();
  };
  
  return (
    <Modal
      title="Concludi lavorazione"
      onClose={onClose}
      size="md"
      overlayOpacity={0.7}
      transition="slide-up"
      fullScreen
      opened={true}>
      <LoadingOverlay visible={loading} overlayBlur={2}/>
      <form onSubmit={form.onSubmit(handleCreateClocking)}>
        <Stepper active={active} onStepClick={setActive} allowNextStepsSelect={false}>
          
          <Stepper.Step>
            <Text size="xl" weight={700} mt="lg" mb="lg">
              Cliente: {clocking.customer.description}
            </Text>
            <Text>
              Concludi lavorazione avviata alle ore {new Date(clocking.dateStart.toString()).toLocaleTimeString()}
            </Text>
            <Space h="lg"/>
            <Text>
              <TextInput
                label="Referente"
                {...form.getInputProps("referente")}
              />
              <p style={{
                textAlign: 'center'
              }}>(Se non si inserisce un referente, sarà obbligatorio caricare una foto)</p>
            </Text>
          </Stepper.Step>
          <Stepper.Step>
            <SignaturePad setSignature={(content) => form.setFieldValue("signature", content)}/>
          </Stepper.Step>
          <Stepper.Step>
            <FileUploader setIsUploading={setLoading}
                          onFileUpload={(id: string) => form.setFieldValue("photos", [...form.values.photos, id])}/>
          
          </Stepper.Step>
        </Stepper>
        
        <Group position="center" mt="xl">
          {active !== 0 && <Button variant="default" onClick={prevStep}>Indietro</Button>}
          {active !== 2 && <Button onClick={nextStep}>Avanti</Button>}
          {active === 2 && <Button type={"submit"} color="teal"
                                   variant="filled" loading={submitting}
                                   disabled={isLoading || loading}>Concludi</Button>}
        </Group>
      </form>
    </Modal>
  )
}

