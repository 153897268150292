import React, {useEffect, useRef, useState} from "react";
import {
  Anchor,
  Box,
  Breadcrumbs,
  Button,
  Col,
  Container,
  Divider,
  Grid,
  Loader,
  LoadingOverlay,
  Select,
  Space,
  Textarea,
  TextInput,
  Title,
} from "@mantine/core";
import {useGetClockingQuery, useUpdateClockingMutation} from "../../redux/apis/clockingApi";
import {useForm} from "@mantine/form";
import {showNotification} from "@mantine/notifications";
import {useKeycloak} from "@react-keycloak/web";
import {useRoute} from "wouter";
import {clockingServices} from "../../constants";
import {IconDownload} from "@tabler/icons-react";
import keycloak from "../../Keycloak";

//TODO: make a reusable function, that's a duplicate
async function downloadFile(id: string, filename: string) {
  const response = await fetch(`/api/document/${id}/file`, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + keycloak.token
    }
  })
  
  if (!response.ok) {
    throw new Error('File download failed');
  }
  
  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'file.pdf';
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
}

export const ClockingDetail = () => {
  // @ts-ignore
  const [, {id}] = useRoute("/clockings/:id");
  
  const {data} = useGetClockingQuery(id);
  const [userId, setUserId] = useState<string>("")
  const [userName, setUserName] = useState<string>("")
  const {keycloak} = useKeycloak();
  
  useEffect(() => {
    keycloak.loadUserProfile().then((u) => {
      setUserId(u.id);
      setUserName(u.lastName + " " + u.firstName);
    });
  }, [keycloak]);
  
  const [updateClocking, {isLoading}] = useUpdateClockingMutation();
  
  const canvasRef = useRef(null);
  
  useEffect(() => {
    if (data?.signature) {
      const img = new Image();
      img.src = data.signature;
      img.onload = () => {
        // @ts-ignore
        const ctx = canvasRef.current.getContext('2d');
        ctx.drawImage(img, 0, 0);
      };
    }
  }, [data]);
  
  const breadcrumbsItems = [
    {title: "Home", href: "/"},
    {title: "Timbrature", href: "/clockings"},
    {title: data?.id, href: `/clockings/${id}`},
  ].map((item, index) => (
    <Anchor href={item.href} key={index}>
      {item.title}
    </Anchor>
  ));
  
  const form = useForm({
    initialValues: {
      dateStart: new Date(),
      dateEnd: null,
      customer: null,
      signature: null,
      service: "",
      serviceDescription: "",
      referente: "",
      photos: [],
      userId: userId,
      userName: userName
    },
  });
  
  useEffect(() => {
    form.setValues({
      dateStart: data?.dateStart,
      dateEnd: data?.dateEnd,
      customer: data?.customer,
      signature: data?.signature,
      service: data?.service,
      serviceDescription: data?.serviceDescription,
      referente: data?.referente,
      photos: data?.photos,
      userId: userId,
    });
  }, [data, userId]);
  
  return data ? (
    <Container fluid
               sx={{
                 backgroundColor: "white",
                 borderRadius: 6,
                 padding: 12,
                 minHeight: "80vh",
               }}>
      <form onSubmit={form.onSubmit(values => {
        updateClocking({id: id, ...values}).then(res => {
          // @ts-ignore
          if (res.error) {
            showNotification({
              message: "Qualcosa è andato storto! Contattare l'amministratore.",
              color: "red"
            })
          } else {
            showNotification({
              message: "Elemento aggiornato con successo!"
            })
          }
        })
      })}>
        <LoadingOverlay
          visible={isLoading}
          zIndex={1000}
        />
        <Box>
          <Title mb={16}>Dettaglio timbratura</Title>
          <Breadcrumbs style={{
            flexWrap: "wrap",
          }}>{breadcrumbsItems}</Breadcrumbs>
          <Space h="lg"/>
          <Divider label={"Dati timbratura"}/>
          <Space h="lg"/>
          <Grid>
            <Col xs={12} sm={4}>
              <TextInput label="Cliente" value={data.customer.description} disabled/>
            </Col>
            <Col xs={12} sm={4}>
              <TextInput label="Data inizio" {...form.getInputProps("dateStart")} disabled/>
            </Col>
            <Col xs={12} sm={4}>
              <TextInput label="Data fine" {...form.getInputProps("dateEnd")} disabled/>
            </Col>
            <Col xs={12} sm={4}>
              <Select data={clockingServices} label="Servizio" required  {...form.getInputProps("service")} disabled/>
            </Col>
            <Col xs={12} sm={4}>
              <TextInput label="Referente" {...form.getInputProps("referente")} disabled/>
            </Col>
            <Col xs={12} sm={4}>
              <label
                className={"mantine-InputWrapper-label mantine-TextInput-label mantine-ittua2"}>Foto</label>
              <div>
                {
                  data.photos?.map((photo) => (
                    <Button style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }} compact color={"blue"} variant={"light"} onClick={() => {
                      try {
                        downloadFile(photo.id, photo.fileName);
                      } catch (error) {
                        console.error(error);
                      }
                    }}><IconDownload size={16} style={{minWidth: 16}}/> Scarica foto
                    </Button>
                  ))
                }</div>
            </Col>
            <Col xs={12} sm={5}>
              <TextInput label="Eseguito da" {...form.getInputProps("userId")} disabled/>
            </Col>
            <Col xs={12}>
              <Textarea label="Descrizione" required {...form.getInputProps("serviceDescription")} disabled/>
            </Col>
          </Grid>
          <Grid>
            <Col xs={12} sm={5}>
              <div><label
                className={"mantine-InputWrapper-label mantine-TextInput-label mantine-ittua2"}>Firma del
                referente</label></div>
              <canvas ref={canvasRef} style={{
                border: '1px solid black',
              }}/>
            </Col>
          </Grid>
          <Space h="lg"/>
        </Box>
        <Space h="xl"/>
        <Button type={"submit"}>Salva</Button>
      </form>
    
    </Container>
  ) : (
    <Loader color="blue"/>
  )
}
